import React, { FC } from 'react';
import { PageLayout, PageHeading } from 'components';
import { graphql } from 'gatsby';

interface MediaReleaseType {
  data: {
    PageDetail: {
      title: string;
      content: string;
      authorName: string;
      date: string;
    };
  };
}

const MediaReleaseDetail: FC<MediaReleaseType> = ({ data }: any) => {
  const {
    MediaReleaseDetail: { title, content, authorName, date },
    strapiLearningHubPage: { banner },
  } = data;
  return (
    <PageLayout
      title="Page"
      showSliderSetting={5}
      showSliderSettingRes={3}
      showSliderSettingResTab={3}
      delaySec={0.8}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      dealTitle="Media Hub Page"
    >
      <div className="container">
        <PageHeading title={title} description={content} level={1} />
        {authorName} {date}
      </div>
    </PageLayout>
  );
};

export default MediaReleaseDetail;
export const query = graphql`
  query MediaReleaseDetailsQuery($slug: String) {
    MediaReleaseDetail: strapiMediaAppearance(slug: { eq: $slug }) {
      title
      content
      slug
      authorName
      date
    }
    strapiLearningHubPage {
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
